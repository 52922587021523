<template>
  <div
    class="flex h-screen flex-col overflow-auto border-r border-black bg-purple-900 text-white"
  >
    <div
      class="flex items-center space-x-4 border-b border-black bg-purple-800 px-4 py-6"
    >
      <div
        class="shadow-cartoon h-[40px] w-[40px] flex-shrink-0 rounded-full bg-white"
      >
        <NuxtLink to="/brands" @click="onClickNavLink">
          <TnImage
            v-if="brandLogo && (!loading || error)"
            :image="brandLogo"
            width="40"
            height="40"
            sizes="40px xs:80px"
            class="rounded-full"
          />
        </NuxtLink>
      </div>
      <div>
        <p class="text-sm text-white text-opacity-60">Brand Hub</p>
        <h3 class="text-tnNeutral-50 block text-base font-medium">
          {{ brand?.name }}
        </h3>
      </div>
    </div>

    <nav class="my-8">
      <template v-for="(navLinksSection, i) in navLinks" :key="`navlinks-${i}`">
        <ul>
          <li v-for="link in navLinksSection" :key="link.name" class="py-2">
            <NuxtLink
              :to="link.href"
              :target="link.target"
              class="flex items-center justify-normal space-x-4 px-8 py-1 tracking-tight text-white transition-colors"
              :class="{
                'font-medium text-opacity-100': link.isActive,
                'text-opacity-80 hover:text-yellow-600': !link.isActive,
              }"
              @click="onClickNavLink(link)"
            >
              <span
                class="inline-block text-xl text-[#80D5FF]"
                :class="{
                  'text-yellow-600': link.isActive,
                }"
              >
                <component :is="link.svg" class="!mb-0" />
              </span>
              <span>{{ link.name }}</span>
            </NuxtLink>
          </li>
        </ul>

        <div class="px-10 py-4">
          <UDivider :ui="{ border: { base: 'flex border-white/10' } }" />
        </div>
      </template>

      <ul>
        <li class="py-2">
          <div
            class="flex cursor-pointer items-center justify-normal space-x-4 px-8 py-1 tracking-tight text-blue-400 transition-colors hover:text-yellow-600"
            @click="onLogout"
          >
            <span class="inline-block text-xl text-blue-400">
              <LogoutIcon class="!mb-0" />
            </span>
            <span>Logout</span>
          </div>
        </li>
      </ul>
    </nav>
    <div class="flex-grow"></div>
    <div class="item-center mb-4 flex flex-col gap-2 text-center">
      <NuxtLink to="/" class="self-center" @click="onClickNavLink">
        <img src="@/assets/tn-logo-brands.svg" alt="Logo" class="h-[100px]" />
      </NuxtLink>

      <div class="flex justify-center gap-4">
        <NuxtLink
          to="https://www.tastenetwork.io/terms-of-service"
          target="_blank"
          class="text-xs text-blue-400 hover:text-blue-200"
        >
          Terms of Service
        </NuxtLink>
        <NuxtLink
          to="https://www.tastenetwork.io/privacy"
          target="_blank"
          class="text-xs text-blue-400 hover:text-blue-200"
        >
          Privacy Policy
        </NuxtLink>
      </div>

      <div class="text-[10px] text-blue-400 opacity-35">
        <div>©️ 2010-{{ new Date()?.getFullYear() }} Wombat Apps LLC.</div>
        <div>All Rights Reservced</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getImageObject } from '~/helpers/images';
import IconDashboard from '~/assets/icons/dashboard.svg?componentext';
import IconStore from '~/assets/icons/store.svg?componentext';
import IconGear from '~/assets/icons/settings-gear.svg?componentext';
import TruckIcon from '~/assets/icons/truck.svg?componentext';
import PromotionIcon from '~/assets/icons/promotion.svg?componentext';
import SupportIcon from '~/assets/icons/support.svg?componentext';
import LogoutIcon from '~/assets/icons/logout.svg?componentext';
import EVENTS from '~/constants/events';

interface NavLink {
  name: string;
  href: string;
  svg: globalThis.Component;
  isActive: boolean;
  target?: string;
}

const route = useRoute();

const { $trackEvent } = useNuxtApp();
const { brandSupportEmailLink } = useUserSupport();

const navLinks = computed<NavLink[][]>(() => [
  [
    {
      name: 'Dashboard',
      href: '/brands/dashboard',
      svg: IconDashboard,
      isActive: route.path.startsWith('/brands/dashboard'),
    },
    {
      name: 'Products',
      href: '/brands/products',
      svg: IconStore,
      isActive: route.path.startsWith('/brands/products'),
    },
    {
      name: 'Promotions',
      href: '/brands/promotions',
      svg: PromotionIcon,
      isActive: route.path.startsWith('/brands/promotions'),
    },
    {
      name: 'Order Management',
      href: '/brands/orders',
      svg: TruckIcon,
      isActive: route.path.startsWith('/brands/orders'),
    },
  ],
  [
    {
      name: 'Settings',
      href: '/brands/settings',
      svg: IconGear,
      isActive: route.path.startsWith('/brands/settings'),
    },
    {
      name: 'Help & Support',
      href: brandSupportEmailLink.value,
      svg: SupportIcon,
      isActive: false,
      target: '_blank',
    },
  ],
]);

const emit = defineEmits(['nav-click']);
const { brand, loading, error } = useBrand() ?? {};
const { clearAuthState } = useCurrentUserDetails();

const brandLogo = computed(() => {
  const logoImage = brand?.value?.logoImage;
  if (!logoImage) {
    return null;
  }

  return getImageObject(logoImage, {
    alt: `${logoImage.name} logo`,
  });
});

const onClickNavLink = (link?: NavLink) => {
  emit('nav-click');

  if (link) {
    $trackEvent(EVENTS.BRAND_SIDEBAR_NAV_LINK_CLICKED, {
      name: link.name,
      href: link.href,
    });
  }
};

const onLogout = async () => {
  try {
    await logout();
    clearAuthState();
    await navigateTo('/login');
  } catch (error) {
    console.error(error);
  }
};
</script>
